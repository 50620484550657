
import AlertHandler from "../../../../assets/js/modules/alertHandler";

const productProcuremenForm = () => {
    const orderQuantityField = document.getElementById('id_order_quantity');
    const receivedQuantityField = document.getElementById('id_received_quantity');
    const statusField = document.getElementById('id_status');
    const form = document.getElementById('productProcuremenFormUpdate');
    const submitButton = document.getElementById('submit-id-submit');


    function checkQuantities(e) {
        const orderQty = parseFloat(orderQuantityField.value) || 0;
        const receivedQty = parseFloat(receivedQuantityField.value) || 0;
        submitButton.disabled = false;

        if (receivedQty > orderQty) {
            AlertHandler.showAlert('Die erhaltene Menge kann nicht größer sein als die Bestellmenge!', 'danger');
            submitButton.disabled = true;
        }

        if (statusField.value === 'ABGESCHLOSSEN' && receivedQty < orderQty) {
            AlertHandler.showAlert('Bei ABGESCHLOSSEN darf die erhaltene Menge nicht kleiner sein als die Bestellmenge!', 'danger');
            submitButton.disabled = true;
        }

        if (statusField.value === 'ABGESCHLOSSEN' && !receivedQty) {
            AlertHandler.showAlert('Bei ABGESCHLOSSEN muss eine Menge eingegeben werden!', 'danger');
            submitButton.disabled = true;
        }

        if (statusField.value === 'TEILLIEFERUNG' && !receivedQty) {
            AlertHandler.showAlert('Bei TEILLIEFERUNG muss eine Menge eingegeben werden!', 'danger');
            submitButton.disabled = true;
        }

        if (statusField.value === 'TEILLIEFERUNG' && receivedQty >= orderQty) {
            AlertHandler.showAlert('Bei vollständiger Lieferung sollte der Status auf ABGESCHLOSSEN gesetzt werden!', 'danger');
            submitButton.disabled = true;
        }
    }

    receivedQuantityField.addEventListener('change', checkQuantities);
    statusField.addEventListener('change', checkQuantities);
    form.addEventListener('submit', checkQuantities);
}

export default productProcuremenForm;