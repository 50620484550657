

import uniteCreate from "./module/uniteCreate";
import unitUpdate from './module/unitUpdate';
import categoryCreate from "./module/categoryCreate";
import categoryUpdate from "./module/categoryUpdate";
import categoryProductCreate from "./module/categoryProductCreate";
import categoryProductUpdate from "./module/categoryProductUpdate";

document.addEventListener('DOMContentLoaded', function () {
    const unitecreate = new uniteCreate;
    const unitupdate = new unitUpdate;
    const categorycreate = new categoryCreate;
    const categoryupdate = new categoryUpdate;
    const categoryproductcreate = new categoryProductCreate;
    const categoryproductupdate = new categoryProductUpdate;
}); 