//  BatchNumberUpdate


import { BaseAjaxSave } from "../../../../assets/js/common/shared_service";

export default class batchNumberUpdate{
    constructor(){
        this.BatchNumberFormUpdate = document.querySelector('#BatchNumberFormUpdate');
        if(!this.BatchNumberFormUpdate) return;
        this.event();
    }
    event(){
        this.BatchNumberFormUpdate.addEventListener('submit', (event) => {
            BaseAjaxSave(event);
        });
    }

}
