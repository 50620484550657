


import AlertHandler from "../../../../assets/js/modules/alertHandler";
import FetchHandler from "../../../../assets/js/assets/FetchHandler";


export default class productUpdate{
    constructor(){
        this.events();
    }
    events(){
        const productsUpdateForm = document.getElementById('productsUpdateForm');
        if(!productsUpdateForm) return;
        productsUpdateForm.addEventListener('submit', this.productUpdateSave.bind(this));
    }
    async productUpdateSave(event){
        event.preventDefault();
        try {
            const { status, body } = await FetchHandler.sendFormData(event.target,{
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest'
                    }
            });
            //console.log('Status:', status);
            //console.log('Response Body:', body);
            if (status === 200 && body.success) {
                AlertHandler.showAlert(body.message, 'success');
                setTimeout(() => {
                    window.location.href = '/products/list/';
                }, 300);
            } else {
                AlertHandler.showAlert(body.errors || 'Ein Fehler ist aufgetreten', 'danger');
            }
        } catch (error) {
            console.error('Caught error:', error);
            AlertHandler.showAlert('Ein Fehler ist aufgetreten: ' + error, 'danger');
        }
    }
}