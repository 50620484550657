
// agriflow/invoices/static/js/invoices.js 

import CreateInvoices from "./module/CreateInvoices";
import UpdateInvoices from "./module/UpdateInvoices";
import ConvertTo from "../../../assets/js/common/ConvertTo";
import PositionView from "../../../assets/js/common/PositionGenrticView";
import CustomerAddressDetail from "../../../assets/js/common/Customer/CustomerAddressDetail";
import InvoicesChangeHistory from "./module/InvoicesChangeHistory.js";
import Sidebar from "../../../assets/js/assets/Sidebar.js";
import ActivitiesList from "../../../activities/static/js/module/ActivitiesList.js";

// ToDo: bestätigen
// import { confirmBtn } from "../../../assets/js/common/Confirm.js";
// import FormLockManager from "../../../assets/js/common/FormLockManager.js"; 
// import ArchiveManager from "../../../assets/js/common/ArchiveModal.js";
import checkReferences from "../../../assets/js/common/CheckReferences.js";
import StornInvoices from "./module/Storno.js";
import loadPaymentTerms from "./module/PaymentTerms.js";
import PaymentTerms from "./module/PaymentTermsCreate.js";
import loadSkonto from "./module/Skonto.js";
import SkontCreat from "./module/SkontoCreate.js";



document.addEventListener('DOMContentLoaded', () => {
    const createinvoices = new CreateInvoices();
    const invoiceupdate = new UpdateInvoices();
    const Invoiceschangehistory = new InvoicesChangeHistory();
    new PaymentTerms();
    new SkontCreat();
    loadPaymentTerms();
    loadSkonto(); 

    const stornoButton = $('<a>', {
        href: '/invoices/list-storno/',
        class: 'btn btn-primary btn-sm ms-2',
        text: 'Stornierte Rechnungen'
    });
    $('.btn-primary[data-permission="create_invoices"]').parent().append(stornoButton);

    // PDF Button
    const openPdfInvoiceButton = document.getElementById('openPdfInvoiceButton')
    if(!openPdfInvoiceButton) return
    openPdfInvoiceButton.addEventListener('click', event => {
        event.preventDefault();
        const pdfUrl = event.currentTarget.getAttribute('data-url');
        var newWindow = window.open(pdfUrl, '_blank');
        if (newWindow) {
            newWindow.focus();
        } 
    });

    const headers = ['Aktion', 'Bezeichnungsnummer', 'Bezeichnung', 'Kosten pro Einheit', 'Steuer', 'Rabatt %', 'Bestellte Menge', 'Gelieferte Menge', 'Einheit', 'Total'];
    const types = {
        'service_positions': {
            'fields': ['id', 'designation_number', 'designation', 'formatted_cost_per_unit', 'formatted_tax', 'formatted_discount', 'pieces', 'delivered_quantity', 'unit', ''],
            'model_type': 'service',
            'rowClass': 'bg-service-table',
            'colspan': 1,
            'app_label': 'invoices',
            'model_name': 'InvoiceServicePosition'
        },
        'object_positions': {
            'fields': ['id', 'designation_number', 'designation'],
            'model_type': 'object',
            'rowClass': 'bg-warning-table',
            'colspans': { 'designation': 8 },
            'app_label': 'invoices',
            'model_name': 'InvoiceObjektPosition'
        },
        'text_positions': {
            'fields': ['id', 'text'],
            'model_type': 'text',
            'rowClass': 'bg-info-table',
            'colspans': { 'text': 9 },
            'app_label': 'invoices',
            'model_name': 'InvoiceTextPosition'
        },
        'machinerys_positions': {
            'fields': ['id', 'designation_number', 'name', 'formatted_cost_per_unit', 'formatted_tax', 'formatted_discount', 'pieces', 'delivered_quantity', 'unit', ''],
            'model_type': 'machinerys',
            'rowClass': 'bg-warning-table',
            'colspans': { 'formatted_tax': 1 },
            'app_label': 'invoices',
            'model_name': 'InvoiceMachinerysPositions' 
        },
            'products_positions': {
            'fields': ['id', 'products__designation_number', 'products__designation', 'formatted_cost_per_unit', 'formatted_tax', 'formatted_discount', 'pieces', 'delivered_quantity', 'unit', ''],
            'model_type': 'products',
            'rowClass': 'bg-products-table',
            'colspan': 1,
            'app_label': 'invoices',
            'model_name': 'DeliveryNoteProducts'
        }
    }; 

    const dataContainer = document.querySelector('#dataContainerIncoice');
    if(!dataContainer) return
    const dynamicUrl = dataContainer.getAttribute('data-url');
    const view = new PositionView(dynamicUrl, 'dataContainerIncoice', headers, types, 'inoices');
    view.init(); 
    window.reloadPositionsList = async function() {
        view.loadData(); 
    }; 

    // Für storno
    const stornoBtn = document.getElementById('stornoBtn');
    if (stornoBtn) { 
        const storno = new StornInvoices(stornoBtn);
        storno.init();
    }

    // Umwandlen in Lieferschein
    const converinvoicetodelivery = new ConvertTo();
    const button = document.getElementById('copyInvoiceToDelevery');
    button.addEventListener('click', () => {
        const dataConvertUrl = button.getAttribute('data-convert-url')
        converinvoicetodelivery.init(dataConvertUrl);
    });

    // Address ansicht
    const customerDetails = document.getElementById('customer-details');
    if(!customerDetails || !CustomerId) return;
    setTimeout(() => {
        const url = customerDetailApi.replace('0', CustomerId);
        CustomerAddressDetail(url);    
    }, 300);
    
    // Sidebar
    const sidebarDiv = document.getElementsByClassName('sidebar-open')[0]; 
    if(sidebarDiv){
        const sidebarContainer = new Sidebar(sidebarDiv);
        sidebarContainer.init();
    }

    // Activities ListView
    const activitieslist = new ActivitiesList(activitiesList, 'activities-incoice-container');
    window.reloadActivitiesList = function () {
        if (typeof activitieslist !== 'undefined' && activitieslist) {
            activitieslist.init();
        }
    };

    if(document.querySelector('[data-permission="delete_invoices"]')){
        checkReferences('invoices', 'invoices', invoicesIdVar);
    }


});
