// tableConfigs.js


// https://chatgpt.com/c/67252693-058c-8005-ac81-003acad26637
export const tableConfigs = {
    offers: {
        formId: '#offerUpdateForm',
        rowTypes: ['service', 'machinerys', 'products'],
        calculations: [
            {
                name: 'subtotal',
                columns: [6, 3, 5, 4],
                formula: (qty, price, discount, taxRate) => {
                    const discountValue = (price * qty) * (discount / 100);
                    const subtotal = (price * qty) - discountValue;
                    /* const tax = subtotal * (taxRate / 100);
                    return subtotal + tax; */
                    const tax = subtotal * (taxRate / 100);
                    return subtotal;
                },
                resultColumn: 8
            },
            {
                name: 'tax',
                columns: [8, 4],
                formula: (subtotal, taxRate) => subtotal * (taxRate / 100),
                resultColumn: 9
            },
            {
                name: 'total',
                columns: [8, 9],
                formula: (subtotal, tax) => subtotal + tax,
                resultColumn: 10
            }
        ],
        footerRows: [
            { label: 'Gesamt', totalName: 'subtotal' },
            { label: 'zzgl. 20% Mwst', totalName: 'tax' },
            { label: 'Gesamtbetrag', totalName: 'total' }
        ],
        footerColspan: 7
    },
    orders: {
        formId: '#inoicesUpdateForm',
        rowTypes: ['service', 'machinerys', 'products'],
        calculations: [
            {
                name: 'subtotal',
                columns: [6, 7, 3, 5],
                formula: (orderedQty, deliveredQty, price, discount) => {
                    const qty = deliveredQty > 0 ? deliveredQty : orderedQty;
                    const discountValue = (price * qty) * (discount / 100);
                    return (price * qty) - discountValue;
                },
                resultColumn: 9
            },
            {
                name: 'tax',
                columns: [9, 4],
                formula: (subtotal, taxRate) => {
                    const tax = subtotal * (taxRate / 100);
                    return tax;
                },
                resultColumn: 10
            },
            {
                name: 'total',
                columns: [8, 9],
                formula: (subtotal, tax) => subtotal + tax,
                resultColumn: 11
            }
        ],
        footerRows: [
            { label: 'Gesamt', totalName: 'subtotal' },
            { label: 'zzgl. 20% Mwst', totalName: 'tax' },
            { label: 'Gesamtbetrag', totalName: 'total' }
        ],
        footerColspan: 8
    },
    inoices: {
        formId: '#offerUpdateForm',
        rowTypes: ['service', 'machinerys', 'products'],
        calculations: [
            {
                name: 'subtotal',
                columns: [6, 7, 3, 5],
                formula: (orderedQty, deliveredQty, price, discount) => {
                    const qty = deliveredQty > 0 ? deliveredQty : orderedQty;
                    const discountValue = (price * qty) * (discount / 100);
                    return (price * qty) - discountValue;
                },
                resultColumn: 9
            },
            {
                name: 'tax',
                columns: [9, 4],
                formula: (subtotal, taxRate) => {
                    const tax = subtotal * (taxRate / 100);
                    return tax;
                },
                resultColumn: 10
            },
            {
                name: 'total',
                columns: [8, 9],
                formula: (subtotal, tax) => subtotal + tax,
                resultColumn: 11
            }
        ],
        footerRows: [
            {label: 'Zwischensumme', totalName: 'subtotal'},
            {label: 'zzgl. 20% Mwst', totalName: 'tax'},
            {label: 'Gesamtsumme', totalName: 'total'}
        ],
        footerColspan: 8  
    },
    delivery: {
        rowTypes: ['service', 'machinerys', 'products'],
        calculations: [
            {
                name: 'subtotal',
                columns: [6, 7, 3, 5, 4],
                formula: (orderedQty, deliveredQty, price, discount) => {
                    const qty = deliveredQty > 0 ? deliveredQty : orderedQty;
                    const baseAmount = price * qty;
                    const discountAmount = baseAmount * (discount / 100);
                    return baseAmount - discountAmount;
                },
                resultColumn: 9
            },
            {
                name: 'tax',
                columns: [9, 4],
                formula: (subtotal, taxRate) => {
                    return subtotal * (taxRate / 100);
                },
                resultColumn: 10
            },
            {
                name: 'total',
                columns: [9, 10], 
                formula: (subtotal, tax) => {
                    return subtotal + tax;
                },
                resultColumn: 11
            }
        ],
        footerRows: [
            { label: 'Zwischensumme', totalName: 'subtotal' },
            { label: 'zzgl. 20% Mwst', totalName: 'tax' },
            { label: 'Gesamtsumme', totalName: 'total' }
        ],
        footerColspan: 8
    }
};
