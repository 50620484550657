
import { BaseAjaxSave } from "../../../../assets/js/common/shared_service";

export default class OfferUpdate {

    constructor(){
        this.offerUpdateForm = document.querySelector('#offerUpdateForm');
        if(!this.offerUpdateForm) return;
        //this.events();
    }
    
    events(){
        let timer;
        const saveForm = () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                BaseAjaxSave({
                    target: this.offerUpdateForm,
                    preventDefault: () => {}
                });
            }, 300);
        };
        this.offerUpdateForm.addEventListener('change', saveForm);
        $(this.offerUpdateForm).find('#id_customer').on('select2:select', saveForm);
        $(this.offerUpdateForm).find('#id_valid_until').on('changeDate', saveForm);

    }
}
