import AlertHandler from '../../../../assets/js/modules/alertHandler'

export default class machinerysUpdate{
    constructor(){
        this.events();
    }

    events(){
        const machinerysUpdate = document.querySelector('#machineryUpdateForm');
        if(machinerysUpdate){
            machinerysUpdate.addEventListener('submit', this.machinerysUpdate.bind(this));
        }
    }

    machinerysUpdate(event){
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value;

        fetch(form.action, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrfToken
            },
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Netzwerkantwort war nicht ok');
            }
            return response.json();
        })
        .then(response => {

            console.log('Server Response:', response);
            if (response && response.success) {
                AlertHandler.showAlert('Erfolgreich aktualisiert', 'success');
            } else {
                let errorMessage = 'Ein unbekannter Fehler ist aufgetreten';
                
                if (response && response.message) {
                    errorMessage = response.message;
                } else if (response && response.errors) {
                    errorMessage = typeof response.errors === 'string' 
                        ? response.errors 
                        : Object.values(response.errors).flat().join(', ');
                }
                
                AlertHandler.showAlert('Fehler: ' + errorMessage, 'danger');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            AlertHandler.showAlert('Ein Fehler ist aufgetreten: ' + error, 'danger');
        });

    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
}