// ArchiveModal.js

import AlertHandler from "../modules/alertHandler";

export default class ArchiveManager {
    constructor(archiveButton) {
        this.archiveButton = archiveButton; 
        this.newButton = null;
    }

    toggleArchive(url) {
        const csrftoken = document.querySelector('[name=csrfmiddlewaretoken]').value; 

        fetch(url, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': csrftoken,
            },
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Netzwerkantwort war nicht ok');
            }
            return response.json();
        })
        .then(data => {
            if (data.success) {
                AlertHandler.showAlert(data.message, 'success');
                if (data.is_archived) {
                    archiveBtn.textContent = 'Dearchivieren';
                    if (!this.newButton) {
                        this.newButton = document.createElement('a');
                        this.newButton.href = data.delete_url;
                        this.newButton.className = 'btn btn-danger btn-sm';
                        this.newButton.textContent = 'Löschen';
                        this.archiveButton.parentNode.insertBefore(this.newButton, this.archiveButton.nextSibling);
                    }
                } else {
                    archiveBtn.textContent = 'Archivieren';
                    if (this.newButton) { 
                        this.newButton.remove();
                        this.newButton = null;
                    }
                }

            } else {
                console.error('Fehler:', data.error);
            }
        })
        .catch(error => {
            console.error('Fehler:', error);
        });
    } 

} 
