
import { BaseAjaxSave } from "../../../../assets/js/common/shared_service";

export default class productCreate{
    constructor(){
        this.productsCreateForm = document.querySelector('#productsCreateForm');
        if(!this.productsCreateForm) return;
        this.event();
    }
    event(){
        this.productsCreateForm.addEventListener('submit', (event) => {
            BaseAjaxSave(event);
        });
    }

}