


export default async function loadUserPermissions() {
    
    if (!document.cookie.includes('access_token=')) {
        return; // Sofort beenden wenn nicht eingeloggt
    }

    console.log(document.cookie.includes('access_token='))

    try {

        const response = await fetch('/api/access/user-permissions/', { 
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) return;  

        const data = await response.json();    
        document.querySelectorAll('[data-permission]').forEach(form => {
            if (!data.permissions.includes(form.dataset.permission)) {
                form.querySelectorAll('button').forEach(button => {
                    button.disabled = true;
                    button.style.opacity = '0.5';
                    button.style.pointerEvents = 'none';
                    button.style.cursor = 'not-allowed';
                });
                form.querySelectorAll('input, select, textarea').forEach(element => {
                    element.disabled = true;
                    element.style.pointerEvents = 'none';
                    element.style.cursor = 'not-allowed';
                });
                form.classList.add('disabled');
            } else {
                form.querySelectorAll('button').forEach(button => {
                    button.disabled = false;
                    button.style.opacity = '1';
                    button.style.pointerEvents = 'auto';
                    button.style.cursor = 'default';
                });
                form.querySelectorAll('input, select, textarea').forEach(element => {
                    element.disabled = false;
                    element.style.pointerEvents = 'auto';
                    element.style.cursor = 'default';
                });
                form.classList.remove('disabled');
            }
        }); 

    } catch {
        return;
    }
}