

export default class FetchHandler {
    static async sendFormData(form, options = {}) {
        const formData = new FormData(form);
        const csrfToken = form.querySelector('input[name="csrfmiddlewaretoken"]').value;

        try {
            const response = await fetch(form.action, {
                method: 'POST',
                headers: {
                    'X-CSRFToken': csrfToken,
                    ...options.headers
                },
                body: formData,
                ...options
            });

            if (!response.ok) {
                const errorText = await response.text(); // Lies die Antwort einmal
                console.error('Server-Fehler:', errorText);
                throw new Error(`Serverfehler: ${response.status}`);
            }

            const data = await response.json();

            return {
                status: response.status,
                body: data
            };
        } catch (error) {
            console.error('Fetch-Fehler:', error.message);
            throw new Error(`Fetch-Fehler: ${error.message}`);
        }
    }
    static async getData(url, options = {}) {
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    ...options.headers
                },
                ...options
            });

            if (!response.ok) {
                const errorText = await response.text(); // Lies die Antwort einmal
                console.error('Server-Fehler:', errorText);
                throw new Error(`Serverfehler: ${response.status}`);
            }

            return response;
        } catch (error) {
            throw new Error(`Fetch-Fehler: ${error.message}`);
        }
    }
}