
import { initStorageTable } from "./module/storage";
import { initStockTable } from "./module/stock";
import { initFuelTypeTable, loadFuelTypeData } from "./module/fuelType";
import { initTransactionTable } from "./module/transaction";
//import { initDataTable } from "./module/dataTabelDeatile";
import { fuelWithdrawal } from "./module/fuelWithdrawal";

import storageFuelModal from "./module/ModalFuel";

document.addEventListener("DOMContentLoaded", function() {
    initStorageTable();
    initStockTable();
    initFuelTypeTable();
    initTransactionTable();
    fuelWithdrawal();

    const fuelTypeStockStatisticsId = document.getElementById('fuelTypeStockStatistics');
    const fuelTypeStockStatisticsUrl = fuelTypeStockStatisticsId ? fuelTypeStockStatisticsId.dataset.url : null;
    if (fuelTypeStockStatisticsId && fuelTypeStockStatisticsUrl) {
        loadFuelTypeData(fuelTypeStockStatisticsUrl);
    }

    // ** ModalFuel.js **
    const storageFuelBtn = document.querySelector('.storageFuelBtn');
    if(!storageFuelBtn) return;
    storageFuelBtn.addEventListener('click', (event) => {
        if(storageFuelBtn && storageFuelBtn.dataset.url){
            const url = storageFuelBtn.dataset.url;
            new storageFuelModal(url, '#myModalServie1');
        }
    })



    // dataTabelDeatile.js
    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const accessToken = getCookie('drfToken');
    if (!accessToken) {
        console.error('Kein Token gefunden!');
        return;
    }

    // Verwendung für Treibstoff-Tabelle
    const fuelTypeTableId = document.getElementById('fuelTypeStock');
    const fuelTypeDetails = fuelTypeTableId ? fuelTypeTableId.dataset.url : null;

    if (fuelTypeTableId && fuelTypeDetails) {
        $('#fuelTypeStock').DataTable({
            ajax: {
                url: fuelTypeDetails,
                headers: {
                    'Authorization': `Token ${accessToken}`
                },
                dataSrc: 'stocks'
            },
            paging: false,
            searching: false,
            info: false,
            scrollY: 100,
            columns: [
                { 
                    data: 'fuel_type',
                    render: data => data ? data.name : ''
                },
                { data: 'quantity' },
                { data: 'warning_limit' },
                { 
                    data: 'storage_location',
                    render: data => data ? data.name : ''
                }
            ],
            language: {
                "decimal":        ",",
                "emptyTable":    "Keine Daten verfügbar",
                "info":          "_START_ bis _END_ von _TOTAL_ Einträgen",
                "infoEmpty":     "0 bis 0 von 0 Einträgen",
                "infoFiltered":  "(gefiltert von _MAX_ Einträgen)",
                "lengthMenu":    "_MENU_ Einträge anzeigen",
                "loadingRecords": "Lädt...",
                "processing":    "Bitte warten...",
                "search":        "Suchen:",
                "zeroRecords":   "Keine passenden Einträge gefunden",
                'paginate': {
                    'next': '<i class="fas fa-angle-right"></i>',
                    'previous': '<i class="fas fa-angle-left"></i>',
                    'first': '<i class="fas fa-angle-double-left"></i>',
                    'last': '<i class="fas fa-angle-double-right"></i>'
                }
            },
        });
    }


    const fuelTypeTransactionTableId = document.getElementById('fuelTypeTransaction');
    const fuelTypeTransactionDetails = fuelTypeTableId ? fuelTypeTableId.dataset.url : null;
    if (fuelTypeTransactionTableId && fuelTypeTransactionDetails) {
        const table = $('#fuelTypeTransaction').DataTable({
            paging: true,
            searching: true,
            info: false,
            ajax: {
                url: fuelTypeTransactionDetails,
                headers: {
                    'Authorization': `Token ${accessToken}`
                },
                data: function(d) {
                    d.person_id = $('#personFilter').val();
                    d.machine_id = $('#machineFilter').val();
                    d.transaction_type = $('#transactionTypeFilter').val();
                    return d;
                },
                dataSrc: function(json) {
                    // HIER rufen wir den Filter auf!
                    if (json.filter_options) {
                        initFilters(json.filter_options);
                    }
                    return json.data;
                }
            },
            columns: [
                { 
                    data: 'fuel_type',
                    render: data => data ? data.name : ''
                },
                {
                    data: 'person',
                    render: data => data ? data.username : ''
                },
                { data: 'transaction_type' },
                { data: 'quantity' },
                {
                    data: 'machine',
                    render: data => data ? data.name : ''
                },
                {
                    data: 'storage_location',
                    render: data => data ? data.name : ''
                }
            ]
        });

        let filtersInitialized = false;  
        const initFilters = (filterOptions) => {
            if (filtersInitialized) return;
            const personFilter = $('#personFilter');
            filterOptions.persons.forEach(person => {
                personFilter.append(`<option value="${person.id}">${person.username}</option>`);
            });
            const machineFilter = $('#machineFilter');
            filterOptions.machines.forEach(machine => {
                machineFilter.append(`<option value="${machine.id}">${machine.name || '-'}</option>`);
            });
            const typeFilter = $('#transactionTypeFilter');
            filterOptions.transaction_types.forEach(type => {
                typeFilter.append(`<option value="${type}">${type}</option>`);
            });
            filtersInitialized = true;
            $('.form-select').on('change', function() {
                table.ajax.reload();
            });
        };
        return table;
    }


    const formObserver = new MutationObserver((mutations) => {
        const formElements = document.querySelector('[name="transaction_type"]');
        if (formElements) {
            formObserver.disconnect();
            initFormLogic();
        }
    });

    formObserver.observe(document.body, {
        childList: true,
        subtree: true
    });

    function initFormLogic() {
        let selectType = document.querySelector('[name="transaction_type"]');
        let selectStorage = document.querySelector('[name="storage_location"]');
        let selectFuel = document.querySelector('[name="fuel_type"]');
        let machineSelect = $('[name="machine"]'); 

        if (!selectType || !selectStorage || !selectFuel) {
            console.log("ERROR: Form elements missing");
            return;
        }

        let originalNames = {};
        selectStorage.querySelectorAll('option').forEach(opt => {
            originalNames[opt.value] = opt.text;
        });
        
    
        function toggleMachineField() {
            if (selectType.value === 'ADD') {
                machineSelect.prop('disabled', true);
            } else {
                machineSelect.prop('disabled', false);
            }
        }

        function checkStorage() {

            toggleMachineField();

            if (selectType.value === 'ADD' && selectFuel.value) {
                fetch('/api/fuel/empty-storage/?fuel_type=' + selectFuel.value)
                    .then(r => r.json())
                    .then(data => {
                        $(selectStorage).empty();
                        data.forEach(item => {
                            let text = item.storage_location__name;
                            if (item.quantity) {
                                text += ` (${item.quantity} L)`;
                            }
                            $(selectStorage).append(new Option(text, item.storage_location_id));
                        });
                        $(selectStorage).trigger('change.select2');
                    });
            }
            
            if (selectType.value === 'REMOVE' && selectFuel.value) {
                fetch('/api/fuel/available-storage/?fuel_type=' + selectFuel.value)
                    .then(r => r.json())
                    .then(data => {
                        $(selectStorage).empty();
                        data.forEach(item => {
                            let text = item.storage_location__name + ` (${item.quantity} L)`;
                            $(selectStorage).append(new Option(text, item.storage_location_id));
                        });
                        $(selectStorage).trigger('change.select2');

                    });
            }
        }
        
        let quantityInput = document.querySelector('[name="quantity"]');
        let warningDiv = document.createElement('div');
        warningDiv.className = 'alert alert-danger mt-2 p-1';
        warningDiv.style.display = 'none';
        quantityInput.parentNode.appendChild(warningDiv);
        function checkQuantity() {
            if (selectType.value === 'REMOVE' && selectFuel.value && selectStorage.value) {
                const quantity = parseFloat(quantityInput.value);
                
                fetch(`/api/fuel/check-stock/?fuel_type=${selectFuel.value}&storage=${selectStorage.value}&quantity=${quantity}`)
                    .then(r => r.json())
                    .then(data => {
                        if (!data.success) {
                            warningDiv.textContent = data.message;
                            warningDiv.style.display = 'block';
                            //quantityInput.setCustomValidity(data.message);
                        } else {
                            warningDiv.style.display = 'none';
                            //quantityInput.setCustomValidity('');
                        }
                    });
            }
        }
    
        // Event Listener für Mengeneingabe
        quantityInput.addEventListener('input', checkQuantity);

        checkStorage();
        $(selectType).on('select2:select', checkStorage);
        $(selectFuel).on('select2:select', checkStorage);
    }





});