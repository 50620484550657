

const CustomerAddressDetail = (url) => {
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`
        }
    })
    .then(response => response.json())
    .then(data => {
        if (data) {

            // customerUpdate
            var url = customerUpdate.replace(/0/, data.id);
            const addressHtml = `
            <div class="card border-0">
                <div class="card-body px-0 pb-3 pt-0 m-0">
                    <p class="m-0 p-0 text-secondary">Unternehmensname: <a href="${url}" class="fw-bold text-decoration-none">${data.company_name || ''}</a></span> 
                    <br> <small>Kundennummer: <span class="fw-bold">${data.customer_number || ''}</span></small></p>
                    <p class="m-0 p-0 text-secondary"><small>Straße & Hausnummer: <span class="fw-bold">${data.address_1 || ''}</span> ${data.address_2 ? ` | Adresszusatz: <span class="fw-bold">${data.address_2}</span>` : ''}</small></p>
                    <p class="m-0 p-0 text-secondary"><small>Postleitzahl: <span class="fw-bold">${data.postcode || ''}</span> | Stadt: <span class="fw-bold">${data.city || ''}</span> <br> Bundesland: <span class="fw-bold">${data.state || ''}</span></small> <small class="m-0 p-0 text-secondary">Land: <span class="fw-bold">${data.country || ''}</span></small></p>
                </div>
            </div>
            `;

            document.getElementById('customer-details').innerHTML = addressHtml; 
            
        } else {
            console.error('Keine Daten erhalten.');
        }
    })
    .catch(error => {
        console.error('Error:', error);
        AlertHandler.showAlert(error || 'An unexpected error occurred.', 'danger');
    });
}
export default CustomerAddressDetail;  

