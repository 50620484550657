

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const initStockTable = () => {
    const accessToken = getCookie('drfToken');
    if (!accessToken) {
        console.error('Kein Token gefunden!');
        return;
    }

    const stockTable = document.getElementById('stockTable')
    if (!stockTable) return;

    var availableHeight = $(window).height() - $('body').offset().top;
    function setTableHeight() {
        const table = $('#stockTable').DataTable({
            processing: true,
            serverSide: true,
            searching: true,
            responsive: true,
            scrollY: availableHeight - 335,
            ajax: {
                url: '/api/fuel/',
                headers: {
                    'Authorization': `Token ${accessToken}`
                },
                data: function(d) {
                    d.type = 'stock';
                    d.fuel_type = $('#fuelTypeFilter').val();
                    d.storage_location = $('#storageFilter').val();
                },
                dataSrc: function(json) {
                    if (json.filter_options) {
                        initFilters(json.filter_options);
                    }
                    return json.data;
                },
                error: function (xhr, error, thrown) {
                    console.error('DataTables Fehler:', error);
                    console.error('Details:', thrown);
                    console.error('Response:', xhr.responseText); 
                }
            },
            columns: [
                {
                    data: 'storage_location',
                    render: function(data) {
                        return data ? data.name : '-';
                    }
                },
                {
                    data: 'fuel_type.name',
                    render: function(data, type, row) {
                        return data || '-';
                    }
                },
                {
                    data: 'warning_limit',
                    render: function(data) {
                        return data ? data.toLocaleString('de-DE') + ' L' : '-';
                    }
                },
                {
                    data: 'quantity',
                    render: function(data) {
                        return data ? data.toLocaleString('de-DE') + ' L' : '0 L';
                    }
                },
            ],
            language: {
                "decimal":        ",",
                "emptyTable":    "Keine Daten verfügbar",
                "info":          "_START_ bis _END_ von _TOTAL_ Einträgen",
                "infoEmpty":     "0 bis 0 von 0 Einträgen",
                "infoFiltered":  "(gefiltert von _MAX_ Einträgen)",
                "lengthMenu":    "_MENU_ Einträge anzeigen",
                "loadingRecords": "Lädt...",
                "processing":    "Bitte warten...",
                "search":        "Suchen:",
                "zeroRecords":   "Keine passenden Einträge gefunden",
                'paginate': {
                    'next': '<i class="fas fa-angle-right"></i>',
                    'previous': '<i class="fas fa-angle-left"></i>',
                    'first': '<i class="fas fa-angle-double-left"></i>',
                    'last': '<i class="fas fa-angle-double-right"></i>'
                }
            },
        });

        let filtersInitialized = false;  
        const initFilters = (filterOptions) => {
            if (filtersInitialized) return;
            $('#storageFilter').empty().append('<option value="">Alle Lager</option>');
            $('#fuelTypeFilter').empty().append('<option value="">Alle Treibstoffe</option>');
            const fuelTypeFilter = $('#fuelTypeFilter');
            filterOptions.fuel_types.forEach(fuel => {
                fuelTypeFilter.append(`<option value="${fuel.id}">${fuel.name}</option>`);
            });
            
            const storageFilter = $('#storageFilter');
            filterOptions.storage_locations.forEach(storage => {
                storageFilter.append(`<option value="${storage.id}">${storage.name}</option>`);
            });
            filtersInitialized = true;
            $('.form-select').on('change', function() {
                table.ajax.reload();
            });
        };
    }
    setTableHeight();
    $(window).resize(function() {
        table.columns.adjust().draw();
    });

}