

import AlertHandler from '../../../../assets/js/modules/alertHandler'
import FetchHandler from '../../../../assets/js/assets/FetchHandler';

export default class categoryUpdate {
    constructor() {
        this.events();
    } 
    events(){
        const categoryUpdateForm = document.getElementById('categoryUpdateForm');
        if(!categoryUpdateForm) return;
        categoryUpdateForm.addEventListener('submit', this.categoryUpdateSave.bind(this))
    }
    async categoryUpdateSave(event){
        event.preventDefault();
        try {
            const { status, body } = await FetchHandler.sendFormData(event.target);
            if (status === 200 && body.success) {
                AlertHandler.showAlert(body.message, 'success');
                setTimeout(() => {
                    window.location.href = '/unit/categories-list/';
                }, 300);
            } else {
                AlertHandler.showAlert(body.errors || 'Ein Fehler ist aufgetreten', 'danger');
            }
        } catch (error) {
            AlertHandler.showAlert('Ein Fehler ist aufgetreten: ' + error, 'danger');
        }
    }

}