


function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}


export const initStorageTable = () => {
    const accessToken = getCookie('drfToken');
    if (!accessToken) {
        console.error('Kein Token gefunden!');
        return;
    }

    const storageTable = document.getElementById('storageTable')
    if (!storageTable) return;

    var availableHeight = $(window).height() - $('body').offset().top;
    function setTableHeight() {
        const table = $('#storageTable').DataTable({
                processing: true,
                serverSide: true,
                searching: true,
                responsive: true,
                scrollY: availableHeight  - 335,
                ajax: {
                url: '/api/fuel/',
                headers: {
                    'Authorization': `Token ${accessToken}`
                },
                data: function(d) {
                    d.type = 'storage';
                },

                dataSrc: function(json) {
                    console.log('Server Data:', json);
                    return json.data;
                }
            },
            columns: [
                {data: 'name'},
            ],
            language: {
                "decimal":        ",",
                "emptyTable":    "Keine Daten verfügbar",
                "info":          "_START_ bis _END_ von _TOTAL_ Einträgen",
                "infoEmpty":     "0 bis 0 von 0 Einträgen",
                "infoFiltered":  "(gefiltert von _MAX_ Einträgen)",
                "lengthMenu":    "_MENU_ Einträge anzeigen",
                "loadingRecords": "Lädt...",
                "processing":    "Bitte warten...",
                "search":        "Suchen:",
                "zeroRecords":   "Keine passenden Einträge gefunden",
                'paginate': {
                    'next': '<i class="fas fa-angle-right"></i>',
                    'previous': '<i class="fas fa-angle-left"></i>',
                    'first': '<i class="fas fa-angle-double-left"></i>',
                    'last': '<i class="fas fa-angle-double-right"></i>'
                }
            },
            "createdRow": function(row, data, dataIndex) {
                const url = `/fuel/?view=storage&pk=${data.id}&action=single`;
                $(row).addClass('clickable-row')
                     .attr('data-url', url)
                     .css('cursor', 'pointer');
            }
        });
    }
    setTableHeight();
    $(window).resize(function() {
        table.columns.adjust().draw();
    });
    $(document).on('click', '.clickable-row', function() {
        window.location.href = $(this).data('url');
    });
}
