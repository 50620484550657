
import AlertHandler from "../../../../assets/js/modules/alertHandler";
import FetchHandler from "../../../../assets/js/assets/FetchHandler";

export default class categoryProductCreate{
    constructor(){
        this.events();
    }
    events(){
        const productCategoryCreateForm = document.getElementById('productCategoryCreateForm');
        if(!productCategoryCreateForm) return;
        productCategoryCreateForm.addEventListener('submit', this.productCategoryCreateSave.bind(this));
    }
    async productCategoryCreateSave(){
        event.preventDefault();
        try {
            const { status, body } = await FetchHandler.sendFormData(event.target);
            if (status === 200 && body.success) {
                AlertHandler.showAlert(body.message, 'success');
                setTimeout(() => {
                    window.location.href = '/unit/products/categories-list/';
                }, 300);
            } else {
                AlertHandler.showAlert(body.errors || 'Ein Fehler ist aufgetreten', 'danger');
            }
        } catch (error) {
            AlertHandler.showAlert('Ein Fehler ist aufgetreten: ' + error, 'danger');
        }
    }
}