
// agriflow/banking/static/js/module/modalBanking.js

import ModalsGeneric from "../../../../assets/js/common/ModalGeneric";

export default function bankingModalOpen() {

    const bankingBtn = document.querySelector('.banking-btn');
    if(!bankingBtn) return;

    const modalService = new ModalsGeneric(); 
    modalService.init('#bankingModal');

    bankingBtn.addEventListener('click', () => {
        const url = bankingBtn.getAttribute('data-url');
        modalService.loadModalData(url);
    });

    document.querySelectorAll('.close-modal-button-banking').forEach(button => {
        button.addEventListener('click', () => {
            modalService.closeModal();
        });
    });    

}