

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const initFuelTypeTable = () => {
    const accessToken = getCookie('drfToken');
    if (!accessToken) {
        console.error('Kein Token gefunden!');
        return;
    }

    const fuelTypeTable = document.getElementById('fuelTypeTable')
    if (!fuelTypeTable) return;

    var availableHeight = $(window).height() - $('body').offset().top;
    function setTableHeight() {
        const table = $('#fuelTypeTable').DataTable({
            processing: true,
            serverSide: true,
            searching: true,
            responsive: true,
            scrollY: availableHeight - 335,
            ajax: {
                url: '/api/fuel/',
                headers: {
                    'Authorization': `Token ${accessToken}`
                },
                data: function(d) {
                    d.type = 'fuel_type';
                },
                dataSrc: function(json) {
                    return json.data;
                },
            },
            columns: [
                {data: 'name'}
            ],
            language: {
                "decimal":        ",",
                "emptyTable":    "Keine Daten verfügbar",
                "info":          "_START_ bis _END_ von _TOTAL_ Einträgen",
                "infoEmpty":     "0 bis 0 von 0 Einträgen",
                "infoFiltered":  "(gefiltert von _MAX_ Einträgen)",
                "lengthMenu":    "_MENU_ Einträge anzeigen",
                "loadingRecords": "Lädt...",
                "processing":    "Bitte warten...",
                "search":        "Suchen:",
                "zeroRecords":   "Keine passenden Einträge gefunden",
                'paginate': {
                    'next': '<i class="fas fa-angle-right"></i>',
                    'previous': '<i class="fas fa-angle-left"></i>',
                    'first': '<i class="fas fa-angle-double-left"></i>',
                    'last': '<i class="fas fa-angle-double-right"></i>'
                }
            },
            "createdRow": function(row, data, dataIndex) {
                const url = `/fuel/?view=fuel_type&pk=${data.id}&action=single`;
                $(row).addClass('clickable-row')
                     .attr('data-url', url)
                     .css('cursor', 'pointer');
            }
        });
    }
    setTableHeight();
    $(window).resize(function() {
        table.columns.adjust().draw();
    });
    $(document).on('click', '.clickable-row', function() {
        window.location.href = $(this).data('url');
    });

}


export const loadFuelTypeData = async (urlFuelTypeData) => {
    const accessToken = getCookie('drfToken');
    if (!accessToken) {
        console.error('Kein Token gefunden!');
        return;
    }

    const LABELS = {
        'statistics': {
            'total_quantity': 'Gesamtbestand',
            'total_added': 'Gesamt hinzugefügt',
            'total_removed': 'Gesamt entnommen'
        },
        'top_machines': {
            'title': 'Top Maschinen',
            'machine__name': 'Maschine',
            'total_consumed': 'Verbrauch'
        },
        'top_persons': {
            'title': 'Top Benutzer',
            'person__username': 'Benutzer',
            'total_consumed': 'Verbrauch'
        },
        'monthly_consumption': {
            'title': 'Monatlicher Verbrauch',
            'month': 'Monat',
            'total': 'Menge'
        },
        'object': {
            'id': 'ID',
            'name': 'Name',
            'qr_code': 'QR Code',
            'warning_limit': 'Warngrenze',
            'company': 'Firma'
        }
    };

    try {
        const response = await fetch(`${urlFuelTypeData}`, {
            method: 'GET',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Accept': 'application/json',
                'Authorization': `Token ${accessToken}`
            }
        });
        if (response.ok) {
            const result = await response.json();

            const {statistics: {object, monthly_consumption, ...stats}} = result;

            console.log(result)

            const statisticItems = [
                // Statistics
                {id: '#totalQuantity', label: 'total_quantity', text: 'Gesamtbestand', source: stats},
                {id: '#totalAdded', label: 'total_added', text: 'Gesamt hinzugefügt', source: stats}, 
                {id: '#totalRemoved', label: 'total_removed', text: 'Gesamt entnommen', source: stats},
                
                {id: '#objectName', label: 'name', text: 'Name', source: object},
                {id: '#objectQr', label: 'qr_code', text: 'QR Code', source: object},
                {id: '#objectWarning', label: 'warning_limit', text: 'Warngrenze', source: object},
                {id: '#objectCompany', label: 'company', text: 'Firma', source: object},
            ];
        
            statisticItems.forEach(item => {
                document.querySelector(item.id).textContent = `${item.text}: ${item.source[item.label]} L`;
            });

            document.querySelector('#objectQr').innerHTML = `
                <a href="${object.qr_code}" download>
                    <img src="${object.qr_code}" alt="QR Code" style="width: 100px;">
                    <br>
                    QR Code herunterladen
                </a>
            `;

            const monthDate = new Date(monthly_consumption[0].month).toLocaleDateString();
            document.querySelector('#monthly').textContent = `Monat: ${monthDate} | Verbrauch: ${monthly_consumption[0].total} L`;
            

            let machinesHtml = '<ol class="list-group">';
            result.statistics.top_machines.forEach(machine => {
                machinesHtml += `
                    <li class="list-group-item">
                        ${machine.machine__name}: ${machine.total_consumed} L
                    </li>
                `;
            });
            machinesHtml += '</ol>';

            document.querySelector('#topMachines').innerHTML = machinesHtml;

            let personsHtml = '<ol class="list-group">';
            result.statistics.top_persons.forEach(person => {
                personsHtml += `
                    <li class="list-group-item">
                        ${person.person__username}: ${person.total_consumed} L
                    </li>
                `;
            });
            personsHtml += '</ol>';
            document.querySelector('#topPersons').innerHTML = personsHtml;

        } else {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

