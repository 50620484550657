

/**
 * Prüft Referenzen für ein Objekt und zeigt einen Tooltip mit Verknüpfungen an
 * 
 * Diese Funktion:
 * - Prüft ob ein Objekt Verknüpfungen hat
 * - Zeigt einen Tooltip mit allen verknüpften Elementen
 * - Hilft Benutzern zu verstehen, wo das Objekt verwendet wird
 * 
 * @param {string} appLabel - Django App Label (z.B. 'invoices')
 * @param {string} modelName - Model Name (z.B. 'invoice')
 * @param {number} objectId - ID des zu prüfenden Objekts
 * 
 * Beispiel:
 * checkReferences('invoices', 'invoice', 123);
 */

import { Tooltip } from 'bootstrap'; 

export default function checkReferences(appLabel, modelName, objectId){
    const deleteButton = document.getElementById("delete-button");
    fetch(`/api/baseajax/check/${appLabel}/${modelName}/${objectId}`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Netzwerk-Antwort war nicht ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data)
            if (Object.keys(data.references).length > 0) {
                deleteButton.href = '#';
                deleteButton.textContent = 'Löschen nicht möglich';

                deleteButton.setAttribute('data-bs-toggle', 'tooltip');
                deleteButton.setAttribute('data-bs-placement', 'top');
                deleteButton.setAttribute('title', `Verknüpft mit: ${Object.keys(data.references).join(', ')}`);
                new Tooltip(deleteButton);
            }
        })
        .catch(error => {
            console.error('Fehler beim Abrufen der Referenzen:', error);
        });
}
