
import { BaseAjaxSave } from "../../../../assets/js/common/shared_service";

export default class productProcurementCreate{
    constructor(){
        this.productProcuremenFormCreate = document.querySelector('#productProcuremenFormCreate');
        if(!this.productProcuremenFormCreate) return;
        this.event();
    }
    event(){
        this.productProcuremenFormCreate.addEventListener('submit', (event) => {
            BaseAjaxSave(event);
        });
    }

}