

import * as bootstrap from 'bootstrap';
import AlertHandler from "../../../../assets/js/modules/alertHandler";

export const fuelWithdrawal = async () => {
    const withdrawalContainer = document.getElementById('fuel-withdrawal-container');
    if (!withdrawalContainer) return;
    
    const url = withdrawalContainer.dataset.url;
    if (!url) return;
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    });
    const data = await response.json();

    const { html, form_data, fuel_stocks, available_machines } = data;
    renderDataMachines(available_machines);
    renderDataFuelStocks(fuel_stocks);
    renderHtml(html, form_data);

}

function renderDataMachines(available_machines) {
    const machineContainer = document.getElementById('machine-selection');
    if (!machineContainer) return;

    machineContainer.innerHTML = `
        <input type="hidden" id="id_machine" name="machine" value="">
        <div class="mb-3">
            <label class="form-label">Maschine auswählen:</label>
            <div class="d-flex flex-wrap machine-buttons justify-content-center">
                ${available_machines.map(machine => `
                    <button 
                        type="button" 
                        class="btn btn-outline-primary machine-select-btn btn-sm mb-2 me-2" 
                        data-machine-id="${machine.id}"
                        data-machine-name="${machine.name}"
                        data-fuel-type="${machine.fuel_type || ''}"
                    >
                        ${machine.name}
                        ${machine.fuel_type ? `<small>(${machine.fuel_type})</small>` : ''}
                    </button>
                `).join('')}
            </div>
        </div>
    `;

    document.querySelectorAll('.machine-select-btn').forEach(btn => {
        btn.addEventListener('click', function() {
            if (this.classList.contains('active')) {
                this.classList.remove('active');
                document.getElementById('id_machine').value = '';
            } else {
                document.querySelectorAll('.machine-select-btn').forEach(b => 
                    b.classList.remove('active'));
                this.classList.add('active');
                

                const machineId = this.dataset.machineId;
            
                // Beide Elemente suchen
                const hiddenInput = document.querySelector('input[name="machine"]');
                const selectElement = document.querySelector('select[name="machine"]');

                // Beide updaten
                if (hiddenInput) hiddenInput.value = machineId;
                if (selectElement) selectElement.value = machineId

            }
        });
    });
}

function renderDataFuelStocks(fuel_stocks){
    const fuelStocksContainer = document.getElementById('fuel-stocks-container');
    if (!fuelStocksContainer) return;

    fuelStocksContainer.innerHTML = `
        <div class="mb-3">
            <h5>Verfügbare Treibstoffe:</h5>
            <div class="list-group">
                ${fuel_stocks.map(stock => `
                    <div class="list-group-item d-flex justify-content-between align-items-center">
                        <div class="text-start">
                            <strong>${stock.fuel_type}</strong>
                            <div class="small">Warnung bei: ${stock.warning_limit} L</div>
                        </div>
                        <span class="badge bg-primary rounded-pill">
                            ${stock.quantity} L
                        </span>
                    </div>
                `).join('')}
            </div>
        </div>
    `;
}

function renderHtml(html, formData) {
    const formContainer = document.getElementById('form-container');
    if (!formContainer) return;

    formContainer.innerHTML = html;  
    document.getElementById('id_storage_location').value = formData.storage_id;
    document.getElementById('id_person').value = formData.user_id;
    document.getElementById('id_transaction_type').value = formData.transaction_type;
    document.getElementById('id_fuel_type').value = formData.fuel_type_id;

        let quantityInput = document.querySelector('[name="quantity"]');
        let selectType = document.querySelector('[name="transaction_type"]');
        let selectFuel = document.querySelector('[name="fuel_type"]');
        let selectStorage = document.querySelector('[name="storage_location"]');
        let warningDiv = document.createElement('div');
        warningDiv.className = 'alert alert-danger mt-2 p-1';
        warningDiv.style.display = 'none';
        quantityInput.parentNode.appendChild(warningDiv);
        
        function checkQuantity() {
            if (selectType.value === 'REMOVE' && selectFuel.value && selectStorage.value) {
                const quantity = parseFloat(quantityInput.value);
                
                fetch(`/api/fuel/check-stock/?fuel_type=${selectFuel.value}&storage=${selectStorage.value}&quantity=${quantity}`)
                    .then(r => r.json())
                    .then(data => {
                        if (!data.success) {
                            warningDiv.textContent = data.message;
                            warningDiv.style.display = 'block';
                        } else {
                            warningDiv.style.display = 'none';
                        }
                    });
            }
        }
        quantityInput.addEventListener('input', checkQuantity);
        document.getElementById('fuelWithdrawalForm').addEventListener('submit', submitFuelWithdrawal);
}

async function submitFuelWithdrawal(event) {
    event.preventDefault();
    
    const form = event.target;
    const quantity = form.querySelector('#id_quantity').value;
    const machineSelect = form.querySelector('select[name="machine"]');
    const machineName = machineSelect.options[machineSelect.selectedIndex].text;

    const machineBtn = document.querySelector('.machine-select-btn.active');
    if (!machineBtn) {
        AlertHandler.showAlert('Bitte wähle erst eine Maschine aus!', 'warning');
        return;
    }

    document.getElementById('confirm-quantity').textContent = quantity;
    document.getElementById('confirm-machine').textContent = machineName;

    const modal = new bootstrap.Modal(document.getElementById('confirmModal'));
    modal.show();

    document.getElementById('confirmBtn').onclick = async () => {
        modal.hide();
        const formData = new FormData(form);
        
        try {
            const response = await fetch(form.action, {
                method: 'POST',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                },
                body: formData
            });

            const data = await response.json();
            
            if (data.success) {
                // console.log("=== SUCCESS ===");
                AlertHandler.showAlert('Erfolgreich gespeichert', 'success');
                setTimeout(() => {
                    window.location.href = '/';
                }, 1000);
            } else {
                AlertHandler.showAlert('Fehler beim Speichern', 'error');
                /* console.log("=== ERROR ===");
                console.log("STATUS:", data.status);
                console.log("MESSAGE:", data.message);
                console.log("ALLE DATEN:", JSON.stringify(data, null, 2)); */
            }
            
        } catch (error) {
            console.error('Error:', error);
        }
    };
}