// BatchNumberCreate



import { BaseAjaxSave } from "../../../../assets/js/common/shared_service";

export default class batchNumberCreate{
    constructor(){
        this.BatchNumberFormCreate = document.querySelector('#BatchNumberFormCreate');
        if(!this.BatchNumberFormCreate) return;
        this.event();
    }
    event(){
        this.BatchNumberFormCreate.addEventListener('submit', (event) => {
            BaseAjaxSave(event);
        });
    }

}