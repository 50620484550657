

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const initTransactionTable = () => {
    const accessToken = getCookie('drfToken');
    if (!accessToken) {
        console.error('Kein Token gefunden!');
        return;
    }
    const transactionTable = document.getElementById('transactionTable')
    if (!transactionTable) return;

    var availableHeight = $(window).height() - $('body').offset().top;
    function setTableHeight() {
        const table = $('#transactionTable').DataTable({
            processing: true,
            serverSide: true,
            searching: true,
            responsive: true,
            pageLength: 50,
            scrollY: availableHeight - 335,
            ajax: {
                url: '/api/fuel/',
                headers: {
                    'Authorization': `Token ${accessToken}`
                },
                data: function(d) {
                    d.type = 'transaction';

                    d.person = $('#personFilter').val();
                    d.machine = $('#machineFilter').val();
                    d.transaction_type = $('#transactionTypeFilter').val();
                    d.fuel_type = $('#fuelTypeFilter').val();
                    d.storage_location = $('#storageFilter').val();
                    d.start_date = $('#startDate').val();
                    d.end_date = $('#endDate').val(); 
                },
                dataSrc: function(json) {
                    if (json.filter_options) {
                        initFilters(json.filter_options);
                    }
                    return json.data;
                }
            },
            columns: [
                {
                    data: 'machine',
                    render: function(data) {
                        return data ? data.name : '-';
                    }
                },
                {
                    data: 'transaction_type',
                    render: function(data) {
                        const types = {
                            'ADD': 'Hinzufügen',
                            'REMOVE': 'Entnahme'
                        };
                        return types[data] || data;
                    }
                },
                {
                    data: 'quantity',
                    render: function(data) {
                        return data ? data.toLocaleString('de-DE') + ' L' : '0 L';
                    }
                },
                {
                    data: 'timestamp',
                    render: function(data) {
                        if (!data) return '-';
                        const date = new Date(data);
                        return date.toLocaleString('de-DE', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                        });
                    }
                },
                {
                    data: 'person',
                    render: function(data) {
                        if (!data) return '-';
                        return `${data.username} ${data.last_name}`;
                    }
                },
                {
                    data: 'fuel_type',
                    render: function(data) {
                        return data ? data.name : '-';
                    }
                },
                {
                    data: 'storage_location',  // Neue Spalte
                    render: function(data) {
                        return data ? data.name : '-';
                    }
                }
            ],
            language: {
                "decimal":        ",",
                "emptyTable":    "Keine Daten verfügbar",
                "info":          "_START_ bis _END_ von _TOTAL_ Einträgen",
                "infoEmpty":     "0 bis 0 von 0 Einträgen",
                "infoFiltered":  "(gefiltert von _MAX_ Einträgen)",
                "lengthMenu":    "_MENU_ Einträge anzeigen",
                "loadingRecords": "Lädt...",
                "processing":    "Bitte warten...",
                "search":        "Suchen:",
                "zeroRecords":   "Keine passenden Einträge gefunden",
                'paginate': {
                    'next': '<i class="fas fa-angle-right"></i>',
                    'previous': '<i class="fas fa-angle-left"></i>',
                    'first': '<i class="fas fa-angle-double-left"></i>',
                    'last': '<i class="fas fa-angle-double-right"></i>'
                }
            },
        });

        let filtersInitialized = false;  
        const initFilters = (filterOptions) => {
            if (filtersInitialized) return;

            $('#personFilter').empty().append('<option value="">Alle Personen</option>');
            $('#machineFilter').empty().append('<option value="">Alle Maschinen</option>');
            $('#transactionTypeFilter').empty().append('<option value="">Alle Typen</option>');
            $('#fuelTypeFilter').empty().append('<option value="">Alle Treibstoffe</option>');
            $('#storageFilter').empty().append('<option value="">Alle Lager</option>');

            const personFilter = $('#personFilter');
            filterOptions.persons.forEach(person => {
                personFilter.append(`<option value="${person.id}">${person.username}</option>`);
            });
    
            const machineFilter = $('#machineFilter');
            filterOptions.machines.forEach(machine => {
                machineFilter.append(`<option value="${machine.id}">${machine.name}</option>`);
            });
    
            const typeFilter = $('#transactionTypeFilter');
            filterOptions.transaction_types.forEach(type => {
                typeFilter.append(`<option value="${type}">${type}</option>`);
            });
    
            const fuelTypeFilter = $('#fuelTypeFilter');
            filterOptions.fuel_types.forEach(fuel => {
                fuelTypeFilter.append(`<option value="${fuel.id}">${fuel.name}</option>`);
            });
    
            const storageFilter = $('#storageFilter');
            filterOptions.storage_locations.forEach(storage => {
                storageFilter.append(`<option value="${storage.id}">${storage.name}</option>`);
            });
            filtersInitialized = true;

            $('.form-select, .date-filter').on('change', function() {
                if (table) {
                    table.ajax.reload();
                }
            });
        };

    }
    setTableHeight();
    $(window).resize(function() {
        table.columns.adjust().draw();
    });

}