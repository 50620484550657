
import CreateStorage from "./module/StorageCreate";
import StorageUpdate from "./module/StorageUpdate";
import InventoryCount from "./module/InventoryCountCreate";
import FetchHandler from "../../../assets/js/assets/FetchHandler";
import { currentstockTable } from "./currentstock/currentstockTable";
import { transactionsTable } from "./transactions/transactionsTable";
import { inventorySelectProduct } from "./module/inventorySelectProduct";

import { initInventoryCount } from "./module/inventoryCountList";

document.addEventListener('DOMContentLoaded', function() {
    inventorySelectProduct();
    new CreateStorage();
    new StorageUpdate();
    new InventoryCount();
    
    
    const inventoryTable = document.getElementById('inventory-table');
    if(inventoryTable){
        currentstockTable();
    }

    const inventoryInputTable = document.getElementById('inventory-input-table');
    if(inventoryInputTable){
        transactionsTable();
    }

    async function getWarenhouse(){
        const response = await FetchHandler.getData(warenhouseDeatile);
        const data = await response.json();
        console.log("Daten:", data);
        if (data.qr_code) {
            const qrImg = document.querySelector('.placeholder');
            qrImg.src = data.qr_code;
            
            const downloadLink = document.createElement('a');
            downloadLink.href = data.qr_code;
            downloadLink.download = '';
            downloadLink.style.cursor = 'pointer';
            
            qrImg.parentNode.replaceChild(downloadLink, qrImg);
            downloadLink.appendChild(qrImg);
        }
    }
    if(typeof warenhouseDeatile !== 'undefined'){
        getWarenhouse();
    }
    if(typeof inventoryCountId !== 'undefined'){
        initInventoryCount(inventoryCountId); 
    }

    /* function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const accessToken = getCookie('drfToken');
    if (!accessToken) {
        console.error('Kein Token gefunden!');
        return;
    }

    const csrftoken = getCookie('csrftoken');
    if (!csrftoken) {
        console.error('Kein Token gefunden!');
        return;
    }

    $('#inventory-products').on('click', '.save-count', function() {
        var $tr = $(this).closest('tr');
        var row = table.row($tr);
        var data = row.data();
        var countedStock = $tr.find('.counted-stock').val();
        var storageLocation = $tr.find('.storage-select').val(); 

        if (!storageLocation) {
            alert('Bitte Lagerplatz wählen!');
            return;
        }

        $.ajax({
            url: '/api/warehouse/inventory-count-line/create/',
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken,
                'Content-Type': 'application/json',
                'Authorization': `Token ${accessToken}`
            },
            data: JSON.stringify({
                inventory_count: inventoryCountId,
                product: data.id,
                storage_location: storageLocation,
                counted_stock: countedStock
            }),
            success: function(response) {
                data.difference = response.difference;
                row.data(data).draw();
            }
        });
    });

    



    var table = $('#inventory-products').DataTable({
        ajax: {
            url: '/api/warehouse/inventory-count/2/products/',
            dataSrc: function(json) {
                if (!json.storage_nodes || json.storage_nodes.length === 0) {
                    console.error('Keine Storage Nodes gefunden.');
                }
                window.storageNodes = json.storage_nodes;
                return json.data;
            }
        },
        columns: [
            { 
                data: 'designation',
                render: function(data, type, row) {
                    return data + '<br><small class="text-muted">' + row.current_locations.join(', ') + '</small>';
                }
            },
            { data: 'designation_number' },
            { 
                data: 'storage_location',
                render: function(data, type, row) {
                    return '<select class="form-select form-select-sm storage-select" data-selected="' + (data || '') + '"></select>';
                }
            },
            { data: 'current_stock' },
            {
                data: 'counted_quantity',
                render: function(data) {
                    return '<input type="number" class="form-control form-control-sm counted-stock" value="' + (data || '') + '">';
                }
            },
            { data: 'difference' },
            {
                data: null,
                render: function() {
                    return '<button class="btn btn-primary btn-sm save-count">Speichern</button>';
                }
            }
        ],
        drawCallback: function() {
            $('.storage-select').each(function(index) {
                var $select = $(this);
                var selectedValue = $select.data('selected');
        
                if ($select.hasClass('select2-hidden-accessible')) {
                    $select.select2('destroy');
                }
        
                $select.empty().append('<option value="">Lagerplatz wählen...</option>');
                storageNodes.forEach(function(node) {
                    var selected = (selectedValue === node.id) ? 'selected' : '';
                    $select.append(`<option value="${node.id}" ${selected}>${node.name}</option>`);
                });
        
                try {
                    $select.select2({
                        placeholder: 'Lagerplatz wählen...',
                        theme: 'bootstrap-5',
                        allowClear: true,
                        width: '100%'
                    });
    
                    if (selectedValue) {
                        $select.val(selectedValue).trigger('change');
                    }
                } catch (error) {
                    console.error('Fehler beim Initialisieren von Select2:', error);
                }
            });
        },
        processing: true,
        serverSide: true,
        searching: true,
        responsive: true,
        language: {
            "decimal":        ",",
            "emptyTable":    "Keine Daten verfügbar",
            "info":          "_START_ bis _END_ von _TOTAL_ Einträgen",
            "infoEmpty":     "0 bis 0 von 0 Einträgen",
            "infoFiltered":  "(gefiltert von _MAX_ Einträgen)",
            "lengthMenu":    "_MENU_ Einträge anzeigen",
            "loadingRecords": "Lädt...",
            "processing":    "Bitte warten...",
            "search":        "Suchen:",
            "zeroRecords":   "Keine passenden Einträge gefunden",
            'paginate': {
                'next': '<i class="fas fa-angle-right"></i>',
                'previous': '<i class="fas fa-angle-left"></i>',
                'first': '<i class="fas fa-angle-double-left"></i>',
                'last': '<i class="fas fa-angle-double-right"></i>'
            }
        },
    });


    const completeModal = new Modal(document.getElementById('completeInventoryModal'));
    const successModal = new Modal(document.getElementById('successModal'));

    document.getElementById('complete-inventory').addEventListener('click', function() {
        completeModal.show();
    });
    document.getElementById('confirmComplete').addEventListener('click', function() {
        fetch('/api/warehouse/inventory-count/' + inventoryCountId + '/complete/', {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken,
                'Content-Type': 'application/json',
                'Authorization': `Token ${accessToken}`
            }
        })
        .then(response => response.json())
        .then(data => {
            completeModal.hide();
            successModal.show();
            document.getElementById('successModal').addEventListener('hidden.bs.modal', function () {
                window.location.reload();
            });
        })
        .catch(error => {
            console.error('Error:', error);
            completeModal.hide();
        });
    }); */

    
});