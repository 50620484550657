
import ModalsGeneric from "../../../../assets/js/common/ModalGeneric";

export default class storageFuelModal{
    constructor(url, modalId){
        this.url = url;
        this.modalId = modalId;
        this.modalFuel = new ModalsGeneric();
        this.initializeModal();
        this.bindButtonEvents();
    }
    initializeModal() {
        this.modalFuel.init(this.modalId);
    }
    bindButtonEvents(){
        if (!this.url) return;
        this.modalFuel.loadModalData(this.url);
    }
    loadModalData(url) {
        this.modalFuel.loadModalData(url);
    }
}