
import Select2 from 'select2';

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}


export const inventorySelectProduct = () => {

    const accessToken = getCookie('drfToken');
    if (!accessToken) {
        console.error('Kein Token gefunden!');
        return;
    }

    const formIds = [
        'deliveryNoteProductsForm', 
        'DeliveryNoteServicePositionCreateForm', 
        'InvoicesProductPositionCreateForm',
        'OrderProductPositionCreateUpdateForm',
        'DeliveryNoteServicePositionCreateForm',
    ];
    const observer = new MutationObserver((mutations) => {
        formIds.forEach(id => {
            const forms = document.querySelectorAll(`#${id}`);
            forms.forEach(form => {
                initInventorySelect(form);
                observer.disconnect();
            });
        });
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true
    });

    const initInventorySelect = (form) => {

        const productSelect = form.querySelector('[name="products"]');
        const inventorySelect = form.querySelector('[name="inventory"]');
        if (!productSelect || !inventorySelect) {
            console.log('Felder nicht gefunden:', { productSelect, inventorySelect });
            return;
        }
        $(inventorySelect).select2({
            placeholder: 'Lagerbestand auswählen...',
            width: '100%',
            dropdownParent: $('#myModalServie1')
        });
    
        $(productSelect).on('select2:select', (event) => {
            loadInventoryData(event, form);
        });
    };

    const loadInventoryData = async (event, form) => {
   
        const productId = event.target.value;
        const inventorySelect = form.querySelector('[name="inventory"]');
        if (!productId || !inventorySelect) return;

        try {
            const response = await fetch(`/api/warehouse/detail/warenhouse-inventory-for-product/?product_id=${productId}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`, 
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();

            if (data.product && Object.keys(data.product).length > 0) {
                const unit = form.querySelector('[name="unit"]');
                const costPerUnit = form.querySelector('[name="cost_per_unit"]');
                const tax = form.querySelector('[name="tax"]');
                const discount = form.querySelector('[name="discount"]');
                if(unit) unit.value = data.product.unit ? data.product.unit: '';
                if(costPerUnit) costPerUnit.value = data.product.cost_per_unit ? data.product.cost_per_unit: ''
                if(tax) tax.value = data.product.tax ? data.product.tax: ''
                if(discount) discount.value = data.product.discount ? data.product.discount: ''
            }

            inventorySelect.innerHTML = '';
            data.results.forEach(item => {
                inventorySelect.add(new Option(item.text, item.id));
            });

        } catch (error) {
            inventorySelect.innerHTML = '';
            inventorySelect.add(new Option('Fehler beim Laden', ''));
        }
        inventorySelect.dispatchEvent(new Event('change'));
    };

}