
import { Modal } from 'bootstrap';

/* function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

const accessToken = getCookie('drfToken');
if (!accessToken) {
    console.error('Kein Token gefunden!');
    return;
}

const csrftoken = getCookie('csrftoken');
if (!csrftoken) {
    console.error('Kein Token gefunden!');
    return;
} */


export function initInventoryCount(inventoryCountId) {

    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }

    const accessToken = getCookie('drfToken');
    if (!accessToken) {
        console.error('Kein Token gefunden!');
        return;
    }

    const csrftoken = getCookie('csrftoken');
    if (!csrftoken) {
        console.error('Kein Token gefunden!');
        return;
    }

    var table;
    $('#inventory-products').on('click', '.save-count', function() {
        var $tr = $(this).closest('tr');
        var row = table.row($tr);
        var data = row.data();
        var countedStock = $tr.find('.counted-stock').val();
        var storageLocation = $tr.find('.storage-select').val(); 

        if (!storageLocation) {
            alert('Bitte Lagerplatz wählen!');
            return;
        }

        $.ajax({
            url: '/api/warehouse/inventory-count-line/create/',
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken,
                'Content-Type': 'application/json',
                'Authorization': `Token ${accessToken}`
            },
            data: JSON.stringify({
                inventory_count: inventoryCountId,
                product: data.id,
                storage_location: storageLocation,
                counted_stock: countedStock
            }),
            success: function(response) {
                data.difference = response.difference;
                row.data(data).draw();
            }
        });
    });


    $('#inventory-products').on('click', '.copy-row', function() {
        var $tr = $(this).closest('tr');
        var data = table.row($tr).data();
    
        fetch(`/api/warehouse/inventory-count/${inventoryCountId}/save-copy/`, {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken,
                'Content-Type': 'application/json',
                'Authorization': `Token ${accessToken}`
            },
            body: JSON.stringify({
                product: data.id,
                storage_location: data.storage_location,
                quantity: data.current_stock,
                batch_number: data.batch_number || ''
            })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(result => {
            console.log('Kopie gespeichert:', result);
            table.ajax.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        });
    });



    table = $('#inventory-products').DataTable({
        ajax: {
            url: `/api/warehouse/inventory-count/${inventoryCountId}/products/`,
            dataSrc: function(json) {
                if (!json.storage_nodes || json.storage_nodes.length === 0) {
                    console.error('Keine Storage Nodes gefunden.');
                }
                window.storageNodes = json.storage_nodes;
                return json.data;
            }
        },
        columns: [
            { 
                data: 'designation',
                render: function(data, type, row) {
                    return data + '<br><small class="text-muted">' + row.current_locations.join(', ') + '</small>';
                }
            },
            { data: 'designation_number' },
            { 
                data: 'storage_location',
                render: function(data, type, row) {
                    return '<select class="form-select form-select-sm storage-select" data-selected="' + (data || '') + '"></select>';
                }
            },
            { data: 'current_stock' },
            {
                data: 'counted_quantity',
                render: function(data) {
                    return '<input type="number" class="form-control form-control-sm counted-stock" value="' + (data || '') + '">';
                }
            },
            { data: 'difference' },
            {
                data: null,
                render: function() {
                    return `
                        <button class="btn btn-primary btn-sm save-count">Speichern</button>
                        <button class="btn btn-secondary btn-sm copy-row"><i class="fas fa-copy"></i></button>
                    `;
                }
            }
        ],
        drawCallback: function() {
            $('.storage-select').each(function(index) {
                var $select = $(this);
                var selectedValue = $select.data('selected');
        
                if ($select.hasClass('select2-hidden-accessible')) {
                    $select.select2('destroy');
                }
        
                $select.empty().append('<option value="">Lagerplatz wählen...</option>');
                storageNodes.forEach(function(node) {
                    var selected = (selectedValue === node.id) ? 'selected' : '';
                    $select.append(`<option value="${node.id}" ${selected}>${node.name}</option>`);
                });
        
                try {
                    $select.select2({
                        placeholder: 'Lagerplatz wählen...',
                        theme: 'bootstrap-5',
                        allowClear: true,
                        width: '100%'
                    });
    
                    if (selectedValue) {
                        $select.val(selectedValue).trigger('change');
                    }
                } catch (error) {
                    console.error('Fehler beim Initialisieren von Select2:', error);
                }
            });
        },
        processing: true,
        serverSide: true,
        searching: true,
        responsive: true,
        language: {
            "decimal":        ",",
            "emptyTable":    "Keine Daten verfügbar",
            "info":          "_START_ bis _END_ von _TOTAL_ Einträgen",
            "infoEmpty":     "0 bis 0 von 0 Einträgen",
            "infoFiltered":  "(gefiltert von _MAX_ Einträgen)",
            "lengthMenu":    "_MENU_ Einträge anzeigen",
            "loadingRecords": "Lädt...",
            "processing":    "Bitte warten...",
            "search":        "Suchen:",
            "zeroRecords":   "Keine passenden Einträge gefunden",
            'paginate': {
                'next': '<i class="fas fa-angle-right"></i>',
                'previous': '<i class="fas fa-angle-left"></i>',
                'first': '<i class="fas fa-angle-double-left"></i>',
                'last': '<i class="fas fa-angle-double-right"></i>'
            }
        },
    });


    const completeModal = new Modal(document.getElementById('completeInventoryModal'));
    const successModal = new Modal(document.getElementById('successModal'));

    document.getElementById('complete-inventory').addEventListener('click', function() {
        completeModal.show();
    });
    document.getElementById('confirmComplete').addEventListener('click', function() {
        fetch('/api/warehouse/inventory-count/' + inventoryCountId + '/complete/', {
            method: 'POST',
            headers: {
                'X-CSRFToken': csrftoken,
                'Content-Type': 'application/json',
                'Authorization': `Token ${accessToken}`
            }
        })
        .then(response => response.json())
        .then(data => {
            completeModal.hide();
            successModal.show();
            document.getElementById('successModal').addEventListener('hidden.bs.modal', function () {
                window.location.reload();
            });
        })
        .catch(error => {
            console.error('Error:', error);
            completeModal.hide();
        });
    });

}