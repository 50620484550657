
import getNextNumerView from '../../../core/static/module/numberRange';
import productCreate from './module/CreateProducts'
import productUpdate from './module/UpdateProducts';
import productProcurementCreate from './module/ProductProcurementCreate';
import productProcurementUpdate from './module/ProductProcurementUpdate';
import batchNumberCreate from './module/BatchNumberCreate';
import batchNumberUpdate from './module/BatchNumberUpdate';

import productProcuremenForm from './module/productProcuremenForm';
import FetchHandler from '../../../assets/js/assets/FetchHandler';

document.addEventListener('DOMContentLoaded', function() {
    new productCreate();
    new productUpdate();
    new productProcurementCreate();
    new productProcurementUpdate();
    new batchNumberCreate();
    new batchNumberUpdate();

    if(document.querySelector('#productProcuremenFormUpdate')){
        productProcuremenForm();
    }
    const serviceCreateForm = document.getElementById('productsCreateForm');
    if(serviceCreateForm){
        getNextNumerView('PRO');
    }

    async function getProductsDetail() {
        try {
            const response = await FetchHandler.getData(productsDeatile);
            const data = await response.json();

            if (data.qr_code) {
                const qrImg = document.querySelector('.placeholder');
                qrImg.src = data.qr_code;
                
                const downloadLink = document.createElement('a');
                downloadLink.href = data.qr_code;
                downloadLink.download = '';
                downloadLink.style.cursor = 'pointer';
                
                qrImg.parentNode.replaceChild(downloadLink, qrImg);
                downloadLink.appendChild(qrImg);
            }

            if (data.image) {
                document.querySelectorAll('.placeholder')[1].src = data.image;
                productImg.src = data.image;
                productImg.loading = 'eager';
            }
        } catch (error) {
            console.error(error);
        }
    }
    if (typeof productsDeatile !== 'undefined') {
        getProductsDetail()
    }
    
    
});